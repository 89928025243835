<template>
  <router-view />
</template>

<script>
  export default {
    name: "Messages"
  };
</script>

<style></style>
